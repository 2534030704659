/***************
メインカラー：#000000
****************/
$def-letter-spc: -0.02em;
$color-midashi: #B66C84;
$color-body: #222222;
$color-inner-link: #FF006C;
$color-accent: #C691B1;
$common-width: 1080px;

body {
  font-family: a-otf-gothic-bbb-pr6n,sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #000;
  margin: 0;
  padding: 0;
}
.pc-only {
  display: block;
}
.sp-only {
  display: none!important;
}
p{
  margin: 0;
  padding: 0;
  letter-spacing: $def-letter-spc;
  color: #262626;
  &.common{
    font-size: 15px;
    line-height: 1.66;
  }
  &.large{
    font-size: 16px;
    color: #000000;
  }
}
.clear{
  clear: both;
}
.common-margin{
  height: 15px;
}
.common-margin.small{
  height: 8px;
}
.common-margin.medium{
  height: 20px;
}
.common-margin.large{
  height: 50px;
}
a{
  color: inherit;
  text-decoration: none;
}
a:hover{
  text-decoration: none;
}
h1,h2,h3,h4,h5 {
  margin: 0;
  padding: 0;
  font-family: a-otf-gothic-bbb-pr6n,sans-serif;
  font-weight: normal;
  letter-spacing: $def-letter-spc;
}
h1{
  margin-bottom: 60px;
  font-size: 34px;
}
h2{
  margin-bottom: 30px;
  font-size: 22px;
}
h3{
  margin-bottom: 35px;
  font-size: 19px;
  color: #535353;
}
ul, li {
  margin: 0;
  padding: 0;
}
ol, ul {
  list-style: none;
}
span{
  &.helv{
    font-family: Helvetica;
    font-size: 16px;
    font-weight: bold;
    padding: 0 5px;
    color: #000000;
  }
  &.en{
    color: #000000;
  }
}//span
input {
  outline: none;
}
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
select, textarea{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
}
select::-ms-expand {
    display: none;
}
input {
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
}

/* フォント類 */
.font-common {
  font-family: a-otf-gothic-bbb-pr6n,sans-serif;
}
.font-a-otf-midashi-go {
  font-family: a-otf-midashi-go-mb31-pr6n,sans-serif;
  font-weight: 600;
  font-style: normal;
}
.font-a-otf-midashi-mi {
  font-family: a-otf-midashi-mi-ma31-pr6n,serif;
  font-weight: 600;
  font-style: normal;
}
@font-face {
	font-family: 'HomepageBaukasten';
  src: url('/wp-content/themes/genius/assets/font/HomepageBaukasten-Bold.eot');
  src:
	     url('/wp-content/themes/genius/assets/font/HomepageBaukasten-Bold.woff') format('woff'),
	     url('/wp-content/themes/genius/assets/font/HomepageBaukasten-Bold.ttf')  format('truetype')
}
@font-face {
	font-family: 'HomepageBaukasten-Book';
  src: url('/wp-content/themes/genius/assets/font/HomepageBaukasten-Book.eot');
  src:
	     url('/wp-content/themes/genius/assets/font/HomepageBaukasten-Book.woff') format('woff'),
	     url('/wp-content/themes/genius/assets/font/HomepageBaukasten-Book.ttf')  format('truetype')
}
@font-face {
	font-family: 'Hoefler Text';
  src: url('/wp-content/themes/genius/assets/font/HoeflerTextRegular.eot');
  src:
	     url('/wp-content/themes/genius/assets/font/HoeflerTextRegular.woff') format('woff'),
	     url('/wp-content/themes/genius/assets/font/HoeflerTextRegular.ttf')  format('truetype')
}
.font-homepage-aukasten {
  font-family: "HomepageBaukasten", sans-serif !important;
}
.font-homepage-aukasten-book {
  font-family: "HomepageBaukasten-Book", sans-serif !important;
}
.font-hoefler {
  font-family: "Hoefler Text";
}


/**********
共通アニメーション
************/
.fadein {
  opacity: 0;
  transform : translate(0, 30px);
  transition : all 800ms;

  &.scrollin{
    opacity: 1;
    transform: translate(0, 0);
  }//.scrollin
}//.fadein

.fadein-side {
  opacity: 0;
  transform : translate(-20px, 0px);
  transition : all 800ms;

  &.scrollin{
    opacity: 1;
    transform: translate(0, 0);
  }//.scrollin
}//.fadein

.fadein-delay {
  opacity: 0;
  transition : all 1200ms;
  transition-delay: 0.5s;

  &.scrollin-delay{
    opacity: 1;
  }//.scrollin
}//.fadein

.common-hover {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}//.common-hover

.appeal-button {
  color: #1C1C1C;
  font-size: 15px;
  text-align: center;
  height: 62px;
  line-height: 62px;
  border-radius: 40px;
  background: rgb(235,242,231);
  background: -moz-linear-gradient(90deg, rgba(235,242,231,1) 0%, rgba(190,235,227,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(235,242,231,1) 0%, rgba(190,235,227,1) 100%);
  background: linear-gradient(90deg, rgba(235,242,231,1) 0%, rgba(190,235,227,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ebf2e7",endColorstr="#beebe3",GradientType=1);
  position: relative;

  a {
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    width: 100%;
  }
}//.appeal-button

.common-button {
  color: $color-accent;
  font-size: 16px;
  text-align: center;
  height: 62px;
  line-height: 58px;
  border-radius: 40px;
  border: 2px solid $color-accent;
  position: relative;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  a {
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    width: 100%;
  }

  &:hover {
    background-color: $color-accent;
    color: #FFF;
  }
}//.common-button


header {
  width: 100%;
  height: 106px;
  background-color: #FFF;
  padding: 0 30px;

  .header-wrapper {
    min-width: 1080px;

    .logo {
      width: 180px;
      float: left;
      margin-top: 31px;
      img {
        width: 100%;
      }
    }//.logo

    .menu-wrapper {
      font-size: 14px;
      text-align: right;
      float: left;
      width: calc(100% - 180px - 200px - 20px);
      width: -webkit-calc(100% - 180px - 200px - 20px);
      margin-right: 20px;
      li {
        display: inline-block;
        margin-right: 15px;
        height: 106px;
        line-height: 106px;
      }
    }//.menu-wrapper

    .contact-wrapper {
      width: 200px;
      float: right;
      .appeal-button {
        width: 100%;
        margin-top: 25px;
        height: 52px;
        line-height: 52px;
      }//.appeal-button
    }//.contact-wrapper
  }//.header-wrapper

}//#header


.fv-wrapper{
  position: relative;
  width: 100%;
  height: 462px;
  background-image: url("/wp-content/themes/genius/assets/img/pc/bg-header.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 82px;
  z-index: 0;

  .inner-wrapper {
    width: 1010px;
    margin: 0 auto;
  }

  .breadcrumb {
    font-size: 12px;
    margin-bottom: 40px;

    li {
      display: inline-block;
    }
  }//.breadcrumb

  .main-title {
    font-size: 38px;
    letter-spacing: 0.12em;
    color: #AA124D;
    margin-bottom: 80px;
  }

  .mask {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 462px;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    z-index: -1;
  }

}//.fv-wrapper
.main-wrapper{
  z-index: 1;
  position: relative;
}
/******************
読み込みインジケーター
*******************/
#initial-mask{
  background: rgb(248,231,241);
  background: -moz-linear-gradient(180deg, rgba(248,231,241,1) 0%, rgba(244,250,246,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(248,231,241,1) 0%, rgba(244,250,246,1) 100%);
  background: linear-gradient(180deg, rgba(248,231,241,1) 0%, rgba(244,250,246,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8e7f1",endColorstr="#f4faf6",GradientType=1);
  width: 100%;
  height: 1000px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  text-align: center;
  .wrapper{
    margin: 0 auto;
    width: 250px;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100%;
    margin-top: 320px;
    background-image: url('/wp-content/themes/genius/assets/img/common/logo-v-l.png');
  }
}

footer {
  width: 100%;
  background: rgb(253,247,250);
  background: -moz-linear-gradient(180deg, rgba(253,247,250,1) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(253,247,250,1) 0%, rgba(255,255,255,1) 100%);
  background: linear-gradient(180deg, rgba(253,247,250,1) 0%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fdf7fa",endColorstr="#ffffff",GradientType=1);  padding-bottom:130px;

  .footer-wrapper {
    margin: 0 auto;
    width: 1130px;

    .logo {
      width: 130px;
      float: left;
      margin-top: 30px;

      img {
        width: 100%;
      }
    }//.logo

    .menu-wrapper {
      font-size: 14px;
      text-align: right;
      float: left;
      width: calc(100% - 180px - 80px - 20px);
      width: -webkit-calc(100% - 180px - 80px - 20px);
      margin-right: 20px;
      li {
        display: inline-block;
        margin-right: 15px;
        margin-top: 48px;
        color: #363636;
      }
    }//.menu-wrapper

    .sns-box {
      width: 70px;
      float: right;
      margin-top: 47px;
      color: #575757;
      .fa-facebook-f {
        font-size: 20px;
        margin-right: 35px;
      }
      .fa-instagram {
        font-size: 22px;
      }
    }//.contact-wrapper

    &.under {
      color: #414141;
      margin-top: 40px;

      .left {
        font-size: 10px;
        width: 50%;
        float: left;
        text-align: left;
        color: #8B7B7B;
      }//.left

      .right {
        font-size: 12px;
        width: 50%;
        float: right;
        text-align: right;
        color: #414141;
        margin-top: -53px;

        .sub-title {
          margin-top: -23px;
        }
      }//.right
    }//.under

  }

}//#footer
